import React from "react";
import { graphql, PageProps } from "gatsby";

import Seo from "@components/Seo";
import Layout from "@components/Layout";
import PostList from "@components/Post/PostList";
import { AllPostDataType } from "@components/Post/type";
import PageView from "@components/View/PageView";
import Pagination from "@components/Pagination";
import Wrapper from "@components/Wrapper";

type TechPageContextType = {
  limit: number;
  numberOfPages: number;
  nextPagePath: string;
  previousPagePath: string;
  pageNumber: number;
  skip: number;
};

type TechPageProps = PageProps<AllPostDataType, TechPageContextType>;

const TechPage = ({ path, data, pageContext }: TechPageProps) => {
  return (
    <Layout>
      <Seo
        title="블로그"
        url={path}
        description="올리브영 Tech의 다양한 기술 고민과 일상 이야기를 소개합니다"
      />
      <Wrapper>
        <PageView title="올리브영 개발자의 고민과 일상 이야기">
          <PostList nodes={data.allMarkdownRemark.nodes} />
          <Pagination {...pageContext} />
        </PageView>
      </Wrapper>
    </Layout>
  );
};

export default TechPage;
export type { TechPageContextType };

export const PostPaginationQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          date(formatString: "YYYY-MM-DD")
          category
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
          tags
        }
        fields {
          slug
        }
      }
    }
  }
`;
