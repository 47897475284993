// extracted by mini-css-extract-plugin
export var active = "Pagination-module--active--f9f33";
export var arrow = "Pagination-module--arrow--6b6c5";
export var container = "Pagination-module--container--d373c";
export var disabled = "Pagination-module--disabled--7e859";
export var item = "Pagination-module--item--749df";
export var left = "Pagination-module--left--d907d";
export var nextPrevBox = "Pagination-module--nextPrevBox--7f501";
export var number = "Pagination-module--number--0a232";
export var pageBtn = "Pagination-module--page-btn--6316c";
export var paginationBox = "Pagination-module--paginationBox--f4a96";
export var wrapper = "Pagination-module--wrapper--c777e";